<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="审核通过" value="2"></el-option>
					<el-option label="审核打回" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="申请时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="审核时间">
				<div class="block">
				<el-date-picker
					v-model="pass_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="get_excle_list">导出搜索条件</el-button>
			</el-form-item>
		</el-form>
		<!-- <div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总运费:{{much_total}}元</span>
			<span>总额外服务费:{{service_charge_total}}元</span>
		</div> -->
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" >
				<!-- <el-table-column type="selection" width="39px"></el-table-column> -->
				<el-table-column label="运单id">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.id}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单编号" >
					<template slot-scope="scope">
						<div class="tab_line_item"> <el-button type="text" @click="tord_search(scope.row.truck_tord_num,'1')">{{scope.row.truck_tord_num}}</el-button></div>
					</template>
				</el-table-column>
                <el-table-column label="审核状态">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}} <span v-if="scope.row.status==3">驳回原因:{{scope.row.fail_reason}}</span></div>
					</template>
				</el-table-column>
				<el-table-column label="司机状态" >
					<template slot-scope="scope">
						<div class="tab_line_item"> <el-button type="text" @click="tord_search(scope.row.driver_tel,'2')">{{scope.row.driver_tel}}</el-button></div>
						<div class="tab_line_item">{{scope.row.driver_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车辆状态" >
					<template slot-scope="scope">
						<div class="tab_line_item"> <el-button type="text" @click="tord_search(scope.row.truck_plate_num,'3')">{{scope.row.truck_plate_num}}</el-button></div>
						<div class="tab_line_item">{{scope.row.truck_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="申请时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
                <el-table-column label="额外服务费">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.service_charge}}元</div>		
					</template>
				</el-table-column>
				<el-table-column label="司机收款（不含额外服务费）">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.much}}元</div>		
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-backtop
		target=".page"
		:bottom="100"
		:visibility-height="50"
		></el-backtop>
		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 100, 200, 500]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>
		<el-dialog top="1vh" width="90%" :visible.sync="is_show">
		<div class="iframe-container" v-if="is_show">
			<iframe
			:src="url"
			frameborder="0"
			allowfullscreen
			></iframe>
		</div>
		</el-dialog>

	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import { Loading } from 'element-ui';
	export default {
		components:{
		},
		data() {
			return {
				url:'', // 跳转链接
				is_show:false, //跳转窗口是否打开
				service_charge_total:'',//总额外服务费
				much_total:'',//总计
				creat_time_arr:'',
				pass_time_arr:'',
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
                    creat_time_start:'', //申请时间-起
                    creat_time_end:'',//申请时间-止
                    pass_time_start:'',//审核时间-起
                    pass_time_end:'',//审核时间-止
					status:'1',//审核状态
				},
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				
				
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {
			get_excle_list(){
				Loading.service(this.options);
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'yufu_check_list_shipper',
						...this.form,
						...this.page,
						num:1000,
					},
					callback:(data)=>{
						//预处理
						for(let item of data.list){
							//申请
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//通过时间
							if(item.pass_time==0){
								item.pass_time_text='无'
							}else{
								item.pass_time_text=this.$my.other.totime(item.pass_time);
							}
							//打回时间
							if(item.umpass_time==0){
								item.umpass_time_text='无'
							}else{
								item.umpass_time_text=this.$my.other.totime(item.umpass_time);
							}
							switch(item.status){
								case '1':item.status_text="待审核";break;
								case '2':item.status_text="审核通过";break;
								case '3':item.status_text="审核驳回";
							}
						}
						this.tord_whole_excle(data.list)
					}
				})
			},
	      	tord_whole_excle(data){
				var shunxu=[
					{key:"truck_tord_num",cname:"运单编号"},
					{key:"status_text",cname:"审核状态"},

					{key:"pass_time_text",cname:"通过时间"},
					{key:"umpass_time_text",cname:"打回时间"},

					{key:"creat_time_text",cname:"提交时间"},
					{key:"fail_reason",cname:"打回原因"},

                    {key:"much",cname:"运费"},
					{key:"service_charge_total",cname:"总服务费"},
					{key:"service_charge_old",cname:"原本服务费"},
					{key:"service_charge",cname:"额外服务费"},
					{key:"admin_pay_total",cname:"需回款总金额"},
					{key:"admin_pay_total_return",cname:"己回款总金额"},
					{key:"bank_ra_num_of_pay_return",cname:"回款流水号"},
					{key:"bank_ra_much_of_pay_return",cname:"回款流水金额"},
					{key:"payed_num",cname:"支付编号"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "垫资列表.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => { 
					loadingInstance.close();
				});
			},
			//打开新窗口搜索运单
			tord_search(data,type){
				// this.url = '#/pages/transit_real_time/tord_act_list?truck_tord_num='+data
				
			// 	if(type ==1){
			// 		// window.open('#/pages/transit_real_time/tord_act_list?truck_tord_num='+data)

			// 		this.url = '#/pages/transit_real_time/tord_act_list?truck_tord_num='+data
			// 	}
			// 	else if(type ==2){
			// 		// window.open('#/pages/app_user/driver_list?user_tel='+data)
			// 		this.url = '#/pages/app_user/driver_list?user_tel='+data
			// 	}
			//    else{
			// 		// window.open('#/pages/app_user/truck_list?truck_plate_num='+data)
			// 		this.url = '#/pages/app_user/truck_list?truck_plate_num='+data
			// 	}
			// 	this.is_show = true
			},
            //审核驳回
            tord_check_unpass(id){
                //提交
                this.$prompt('请输入驳回原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    }).then(({ value }) => {
                        this.$my.net.req({
                            data:{
                                mod:'truck_tord_real_time',
                                ctr:'yufu_check_unpass',
                                id,
                                fail_reason:value
                            },callback:(data)=>{
                                this.$my.other.msg({
                                    type:"success",
                                    str:'操作成功'
                                });

                                //刷新
                                this.get_page_data()
                            }
                        })
                    }).catch(() => {   
                });
            },
            //审核通过
            tord_check_pass(id){
                //提交
                this.$my.net.req({
                    data:{
                        mod:'truck_tord_real_time',
                        ctr:'yufu_check_pass',
                        id,
                    },
                    callback:(data)=>{
                    
                        //提醒
                        this.$my.other.msg({
                            str:'操作成功',
                            type:'success'
                        });
                        
                        //刷新本页
                        this.get_page_data()
                    },
                });
            },

			//搜索
			page_ser(){
				//读取第一页
				this.page.p=1
				if (this.creat_time_arr) {
					this.form.creat_time_start = parseInt(this.creat_time_arr[0] / 1000);
					this.form.creat_time_end= parseInt(this.creat_time_arr[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
				}
				if(this.pass_time_arr){
					this.form.pass_time_start = parseInt(this.pass_time_arr[0] / 1000);
				    this.form.pass_time_end = parseInt(this.pass_time_arr[1] / 1000);
				}else{
					this.form.pass_time_start =''
				    this.form.pass_time_end =''
				}
				//读取
				this.get_page_data()
			},
			
			//清空查询条件
			ser_para_clear(e){
				this.form={
                    truck_tord_num:'',//货源编号
                    creat_time_start:'', //申请时间-起
                    creat_time_end:'',//申请时间-止
                    pass_time_start:'',//审核时间-起
                    pass_time_end:'',//审核时间-止
					status:'',
				}
				this.creat_time_arr=''
				this.pass_time_arr = ''
				if(e !=1){
					//读取
					this.get_page_data()
				}
			
				
			},

			//导出搜索结果
			tord_export_open(){//打开界面

				//置入条件
				this.tord_export.rull=this.form

				//打开界面
				this.tord_export.is_show++
			},
			
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					// return;
				}

				//置为加载中
				this.list.loading=true;
				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'yufu_check_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						if(data.code ==0){
							//加载完毕
							this.list.loading=false
							//总数
							this.list.total=data.msg.max

							// this.much_total = data.msg.total.much_total
							// this.service_charge_total = data.msg.total.service_charge_total
							//预处理
							for(let item of data.msg.list){
								//申请
								item.creat_time_text=this.$my.other.totime(item.creat_time);

                                switch(item.status){
									case '1':item.status_text="待审核";break;
									case '2':item.status_text="审核通过" + this.$my.other.totime(item.pass_time);break;
									case '3':item.status_text="审核驳回";
								}
                                switch(item.driver_status){
									case '1':item.driver_status_text="待审核";break;
									case '2':item.driver_status_text="审核通过";break;
									case '3':item.driver_status_text="审核驳回";
								}
								switch(item.truck_status){
									case '1':item.truck_status_text="待审核";break;
									case '2':item.truck_status_text="审核通过";break;
									case '3':item.truck_status_text="审核驳回";
								}
							}
							//渲染
							this.list.data=data.msg.list

						}else{
							this.$my.other.msg({
								str:data.error_info,
								type:'info'
							});
							this.list.loading=false
							this.list.data=[]
						}

					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:88%;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		
		span {
			margin-left: 20px;
		}
	}
	.elfrom_item{
		width:auto;
		margin-right:10px;
	}
	/deep/.el-button--text{
		color: #409eff !important;
	}
	.btn_delete{
		color:red !important
	}
	.iframe-container {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%; /* 16:9 aspect ratio */
		height: 0;
	}
	.iframe-container iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
</style>